import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export const FirstTimersGuideV2Form = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
  checkHP,
  hasHPEntry,
}) => {
  const captchaRef = useRef();

  return (
    <form
      method="post"
      action="https://www.pages03.net/powdr/2025DigitalBrochure/PA_Brochure"
      pageId="21172580"
      siteId="614398"
      parentPageId="21172578"
    >
      {/* <!-- DO NOT REMOVE HIDDEN FIELD sp_exp --> */}
      <input type="hidden" name="formSourceName" value="StandardForm" />
      <input type="hidden" name="sp_exp" value="yes" />

      <p>Sign up for our emails to stay in the know on all things Woodward!</p>
      <h2>Get Started with our Summer Camp Guide</h2>

      <input
        name="First Name"
        id="control_COLUMN3"
        className="form-control"
        type="text"
        placeholder="First Name *"
        required
      />

      <input
        name="Last Name"
        id="control_COLUMN4"
        className="form-control"
        type="text"
        required
        placeholder="Last Name *"
      />

      <input
        name="Email"
        id="control_EMAIL"
        className="form-control"
        type="email"
        placeholder="Email *"
        required
      />

      <input
        id="control_COLUMN50"
        name="2ndName"
        type="hidden"
      />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified || hasHPEntry}>{buttonText || 'Request Info'}</button>
    </form>
  );
};

FirstTimersGuideV2Form.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  checkHP: PropTypes.func.isRequired,
  hasHPEntry: PropTypes.bool.isRequired,
};

FirstTimersGuideV2Form.defaultProps = {
  buttonText: null,
};
